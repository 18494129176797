<template>
  <div class="c-library">
    <div class="c-library__title">
      <h2 class="c-library__title-subtitle">Sparrow shares</h2>
      <h1 class="c-library__title-text c-library__title_blue">expert media views and market insights</h1>
    </div>
    <div class="c-library__description">
      <p class="c-library__description-text">Read about Sparrow in the media, thought leadership on digital asset products and solutions, business achievements and new product offerings.</p>
    </div>

    <Card></Card>

  </div>
</template>

<script>
  import Card from '@/components/library/NewsCard.vue'
  export default {
    name: 'NewsMediaRoomContent',
    components: {
      Card,
    },
    data() {
      return {
      }
    },
  }
</script>
